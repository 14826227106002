<template>
  <Modal
    as-form
    persistent
    :size="ModalSize.MD"
    :title="$t('remove-logo-modal.title')"
    :submit-button-text="$t('common.delete')"
    @submit="emit('submit')"
  >
    <template #activator="{ activatorProps }">
      <Btn
        v-bind="activatorProps"
        icon
        class="absolute top-1 right-1"
        :variant="BtnVariant.FILLED"
        :color="BtnColor.SURFACE"
        :shape="BtnShape.ROUNDED"
      >
        <Icon :icon="TrashSvg" />
      </Btn>
    </template>

    <template #content>
      <div class="text-lg py-10 text-center md:whitespace-pre-wrap">
        {{ $t('remove-logo-modal.description') }}
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import { Modal, ModalSize } from '~/components/modals/modal';
import { Btn, BtnColor, BtnShape, BtnVariant } from '~/components/btn';
import { Icon } from '~/components/icon';
import TrashSvg from '~/assets/icons/trash.svg?component';

const emit = defineEmits<{
  submit: [];
}>();
</script>
